import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="tool-form"
export default class extends Controller {
  // add a target for the name field
  static targets = ["name", "func", "parameters", "toolSelect"]

  connect() {
    this.tools = JSON.parse(this.element.dataset['tools'])
    const myTextArea = this.element.querySelector("#tool_parameters")
    const json_editor = this.makeJsonEditor(myTextArea)

    this.toggleFields($(this.toolSelectTarget).val());

    // this.element.querySelector('[input=submit]').addEventListener("click", (event) => {
    //   if (json_editor.state.lint.marked.length > 0) {
    //     let error = json_editor.state.lint.marked.map(m => { return m.__annotation.message }).join(', ')
    //     alert(`Parameters Error:\n\n${error}`)
    //     return false
    //   }
    // })
  }

  handleToolSelect(event) {
    const originalSelect2Event = event.detail.event
    let value = originalSelect2Event.target.value
    this.toggleFields(value)
  }

  toggleFields(value) {
    const tool = this.tools[value]

    if (value == "custom") {
      this.funcTarget.classList.remove("hidden")
      this.makeRubyEditor(this.element.querySelector("#tool_func"))

    } else {
      this.funcTarget.classList.add("hidden")

    }

    if (tool.parameters) {
      this.parametersTarget.classList.remove("hidden")
    } else {
      this.parametersTarget.classList.add("hidden")
    }
  }

  makeRubyEditor(element) {
    const editor = CodeMirror.fromTextArea(element, {
      lineNumbers: true,
      mode: {
        name: "ruby",
        version: 3,
        singleLineStringErrors: false
      },
      lint: true,
      gutters: ["CodeMirror-lint-markers"],
      lineWrapping: true,
      theme: '3024-night'
    });
    editor.display.wrapper.style.height = "auto"

    return editor
  }


  makeJsonEditor(element) {
    const editor = CodeMirror.fromTextArea(element, {
      lineNumbers: true,
      mode: 'application/json',
      lint: true,
      height: 'auto',
      viewportMargin: Infinity,
      gutters: ["CodeMirror-lint-markers"],
      lineWrapping: true,
      theme: '3024-night'
    });
    editor.display.wrapper.style.height = "auto"

    return editor
  }

}
