import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="document-index-form"
export default class extends Controller {
  static targets = ['configField', 'loaderConfigFields', 'loaderSelect', 'styleSelect', 'files', 'loaderSelectWrapper']

  connect() {
    this.loaders = JSON.parse(this.element.dataset['loaders'])
    this.styles = JSON.parse(this.element.dataset['styles'])
    this.config = JSON.parse(this.element.dataset['config'])

    this.toggleLoader($(this.loaderSelectTarget).val());
    this.toggleStyle($(this.styleSelectTarget).val());

    const myTextArea = this.element.querySelector("#document_index_parameters")
    this.makeJsonEditor(myTextArea)

  }

  handleLoaderSelect(event) {
    const originalSelect2Event = event.detail.event
    let value = originalSelect2Event.target.value
    this.toggleLoader(value)
    // console.log(value);
  }

  handleStyleSelect(event) {
    const originalSelect2Event = event.detail.event
    let value = originalSelect2Event.target.value
    this.toggleStyle(value)
  }

  serializeConfigs() {
    let inputs = this.loaderConfigFieldsTarget.querySelectorAll("input, textarea")
    console.log(inputs);
  }

  toggleStyle(value) {
    let selectedStyle = this.styles[value]
    if (selectedStyle.loaders == 'SimpleDirectoryReader') {
      // set the loaderSelectTarget to SimpleDirectoryReader
      // hide the loaderSelectTarget
      this.loaderSelectWrapperTarget.classList.add('hidden')
      $(this.loaderSelectTarget).val('SimpleDirectoryReader').trigger('change')
      // only allow csv and xlsx  and xls files
      this.filesTarget.querySelector('input[type=file]').setAttribute('accept', '.xlsx, .xls, .csv')
      this.filesTarget.querySelector('input[type=file]').removeAttribute('multiple')
    } else {
      this.loaderSelectWrapperTarget.classList.remove('hidden')
      // remove the accept attribute
      this.filesTarget.querySelector('input[type=file]').removeAttribute('accept')
      this.filesTarget.querySelector('input[type=file]').setAttribute('multiple', true)
    }
  }

  toggleLoader(value) {
    let selectedLoader = this.loaders[value]

    if (selectedLoader.files) {
      this.filesTarget.classList.remove('hidden')
    } else {
      this.filesTarget.classList.add('hidden')
    }

    const loaderConfigSchema = selectedLoader.config

    this.loaderConfigFieldsTarget.innerHTML = ""
    Object.keys(loaderConfigSchema).forEach(key => {
      const type = loaderConfigSchema[key]

      let input
      let label = document.createElement('label')
      label.classList.add('capitalize')
      label.innerHTML = key.replaceAll("_", " ")

      if (type == 'Array') {
        input = document.createElement('textarea')
        input.placeholder = `${key} - one per line`
        input.rows = "10"
        input.classList.add(
          "block",
          "mt-1.5",
          "mb-5",
          "w-full",
          "rounded-md",
          "shadow-sm",
          "font-light",
          "text-base",
          "md:text-sm",
          "focus:ring-primary-500",
          "focus:border-primary-500",
          "border-gray-300",
          "dark:bg-darkPrimary-800",
          "dark:border-darkPrimary-900",
          "dark:text-darkPrimary-300"
        )

        if (this.config[key]) {
          let val
          if (!Array.isArray(this.config[key])) {
            try {
              val = JSON.parse(this.config[key])
            } catch (error) {
              val = []
            }

          } else {
            val = this.config[key]
          }

          if (Array.isArray(val)) {
            input.value = val.join("\n")
            input.rows = val.length + 3
          }
        }
      } else {
        if (type == 'Text') {
          input = document.createElement('textarea')
        } else {
          input = document.createElement('input')
          input.type = 'text'
        }

        input.placeholder = key
        input.classList.add("block",
          "w-full",
          "mt-1.5",
          "mb-5",
          "rounded-md",
          "shadow-sm",
          "font-light",
          "text-base",
          "md:text-sm",
          "focus:ring-primary-500",
          "focus:border-primary-500",
          "border-gray-300",
          "dark:bg-darkPrimary-800",
          "dark:border-darkPrimary-900",
          "dark:text-darkPrimary-300")
        if (this.config[key]) {
          input.value = this.config[key]
        }

      }
      input.name = `document_index[${key}]`
      this.loaderConfigFieldsTarget.appendChild(label)
      this.loaderConfigFieldsTarget.appendChild(input)
    })
  }

  makeJsonEditor(element) {
    CodeMirror.fromTextArea(element, {
      lineNumbers: true,
      mode: 'application/json',
      lint: true,
      gutters: ["CodeMirror-lint-markers"],
      lineWrapping: true,
      theme: '3024-night'
    });
  }
}
